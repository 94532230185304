<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :times="headTime" languageType="EN"></headers>
    <img src="@/assets/speaial_activity_img/imageEn/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="dataTime" languageType="EN">
      <img src="@/assets/speaial_activity_img/imageEn/level_seven_title.png" class="title" alt="">
    </TitleAndTime>
    <div class="main">
      <BarChart3D :data="echratData" languageType="EN"></BarChart3D>
    </div>
    <Annotation languageType="EN"></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import BarChart3D from '@/components/Big/BarChart3D'
import TitleAndTime from '@/components/Big/TitleAndTime.vue'
import Annotation from '@/components/Big/Annotation'
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  components: {
    BarChart3D,
    Headers,
    TitleAndTime,
    Annotation,
  },
  watch: {
    data(newValue, oldValue) {
      this.init(newValue)
    },
  },
  data() {
    return {
      echratData: [],
      headTime: '',
      dataTime: '',
    }
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(data) {
      this.sevenData = []
      if (data && data.length != 0) {
        console.log('楼层7', data)
        this.dataTime = this.$dateFormat(data[0].time)
        // data.forEach((item, index) => {
        //   if (index > 9) return
        //   let num = 11 - item.sort
        //   this.echratData.dataX.push(item.industryNameEn)
        //   this.echratData.data.push(num)
        // })
        this.echratData = data.map((res) => {
          return {
            ...res,
            sort: res.sort,
            industryNameEn: res.industryNameEn.replace(/\s/g, '</br>'),
          }
        })
        console.log('echratData', this.echratData)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/en.scss';
</style>
